import { Controller } from "@hotwired/stimulus";
import {
  HMSReactiveStore,
  selectLocalPeer,
  selectPeers,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  HMSLogLevel
} from "@100mslive/hms-video-store";

const hms = new HMSReactiveStore();

// by default subscriber is notified about store changes post subscription only, this can be
// changed to call it right after subscribing too using this function.
hms.triggerOnSubscribe(); // optional, recommended

const hmsActions = hms.getActions();
hmsActions.setLogLevel(HMSLogLevel.WARN); // optional, recommended

const hmsStore = hms.getStore();

// Connects to data-controller="hms"
export default class extends Controller {


  static targets = [
    "mainstage",
    "ministage",
    "expertimage",
    "join",
    "leave",
    "popup",
    "leavebutton",
    "micbutton",
    "meetbutton",
    "muteaudio",
    "mutevideo"
  ];

  static values = {
    roomcode: String,
    username: String,
    userslug: String,
    usertype: String,
    expertname: String,
    expertslug: String,
  };

  connect() {
    this.popupTarget.classList.add("invisible")
    this.ministageTarget.classList.add("invisible")
    this.join();
  };
  
  disconnect() {
    this.leave();
  };

 
  
  join = async () => {

    // make sure the localPeer is not already connected
    var localPeer = hmsStore.getState(selectLocalPeer);
    if (localPeer) {
      console.log("Peer is already connected");
      return;
    };
  
    // show the popup
    this.popupTarget.classList.remove("invisible")
  
    const authToken = await this.getAuthToken();
  
    // join room using username and auth token
    const config = {
      userName: this.usernameValue,
      authToken: authToken,
      autoManageVideo: true,
      settings: {
        isAudioMuted: true,
        isVideoMuted: false
      },
      rememberDeviceSelection: true  
    };
  
    await hmsActions.join(config);
    
    hmsStore.subscribe(() => {
      const peers = hmsStore.getState(selectPeers);
      
      peers.forEach((peer) => {
        if (peer.videoTrack) {
          const isLocal = peer.isLocal;
          const isWaiting = peer.roleName.includes("waiting");
          
          if (isWaiting) {
            hmsActions.attachVideo(peer.videoTrack, this.mainstageTarget);
          } else {
            // detach the video first from the mainstage after waiting-room
            hmsActions.detachVideo(peer.videoTrack, this.mainstageTarget);
            if (isLocal) {
              // its the local peer
              hmsActions.attachVideo(peer.videoTrack, this.ministageTarget);
              this.ministageTarget.classList.remove("invisible")
            } else {
              //  its a remote peer
              hmsActions.attachVideo(peer.videoTrack, this.mainstageTarget);
            };
          };
        };   
      });
      hmsActions.setLocalVideoEnabled(true);
    }, selectPeers);
    
    this.expertimageTarget.classList.add("hidden");
    this.popupTarget.classList.add("invisible");
  };
  


  meet = async () => {
    const localPeer = hmsStore.getState(selectLocalPeer);
    const targetRole = this.usertypeValue === "expert" ? "host" : "guest";
    // dont change role if the user is already a host or guest
    if (localPeer.roleName === targetRole) {
      console.log("User is already a " + targetRole);
      return;
    };
    await hmsActions.changeRoleOfPeer(localPeer.id, targetRole, true);
    await hmsActions.setLocalAudioEnabled(true);
    console.log("User " + this.usernameValue + " is now a " + targetRole);
    this.meetbuttonTarget.disabled = true;
  };
  
  
  // Leave the room
  leave = async () => {

    // find out if the user is connected to the room
    const localPeer = hmsStore.getState(selectLocalPeer);
    if (localPeer) {
      this.expertimageTarget.classList.remove("hidden");
      await hmsActions.leave();
    } else {
      console.log("Peer is not connected");
    };
    
    window.location.href = `/${this.usertypeValue}/bookings`;
  };

  // Get the auth token to connect to the room
  getAuthToken = async () => {
    // get the credentials for this experts room
    const role = this.usertypeValue === "expert" ? "host-waiting" : "guest-waiting";

    const tokenEndpoint = `/expert/${this.expertslugValue}/token?role=${role}`;
    const response = await fetch(tokenEndpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      }
    });
    console.log(response.text);
    return response.text();
  };
  

  // Mute and unmute audio
  toggleaudio = async () => {
    const audioEnabled = !(hmsStore.getState(selectIsLocalAudioEnabled));
    await hmsActions.setLocalAudioEnabled(audioEnabled);

  };


  // Mute and unmute video
  togglevideo = async () => {
    const videoEnabled = !hmsStore.getState(selectIsLocalVideoEnabled);
    if (videoEnabled) {
      this.expertimageTarget.classList.add("hidden");
      this.ministageTarget.classList.remove("invisible")
    } else {
      this.expertimageTarget.classList.remove("hidden");
      this.ministageTarget.classList.add("invisible")
    };
    await hmsActions.setLocalVideoEnabled(videoEnabled);
  };

  



  
  

}



