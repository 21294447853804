import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="checkout"
export default class extends Controller {
  
  static values = {
    timeutc: String,
    expert: String
  };

  

  openDashboard() {
    // grab all information selected by the client
    const timeutc = this.data.element.dataset.timeutc;
    const expert = this.expertValue;
    console.log("timeutc: " + timeutc + " expert: " + expert);


    // const http = new XMLHttpRequest()
    // const url = '/checkout/#{expert}?timeutc=#{timeutc}'
    // http.open("GET", url)
    // http.send()

    // http.onload = () => console.log(http.responseText)
  };

  connect() {
    
  };
};
