import { Controller } from "@hotwired/stimulus"
import Dropzone from 'dropzone'

export default class extends Controller {
  
  static targets = ["input", "upload"];

  connect() {
    let url = this.data.get("url");
    
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    let dropzoneOptions = {
      paramName: "photo",
      maxFiles: 1,
      acceptedFiles: "image/*",
      dictDefaultMessage: "Drag and drop a file here",
      dictFileTooBig: "File is too big ({{filesize}}MB). Max file size: {{maxFilesize}}MB.",
      init: function() {
        this.on("maxfilesexceeded", function(file) {
          this.removeAllFiles();
          this.addFile(file);
        });
      },
      url: url,
      headers: {
        "X-CSRF-Token": token
      }
    };
    this.dropzone = new Dropzone(this.inputTarget, dropzoneOptions);
  }

  disconnect() {
    this.dropzone.destroy();
  }

  choose() {
    this.dropzone.hiddenFileInput.click();
  }
}
