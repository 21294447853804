import { tns } from 'tiny-slider';
const sliderContainer = document.querySelector('.tiny-slider');

if (sliderContainer) {
  const slider = tns({
    nav: true,
    items: 1,
    container: '.tiny-slider',
    loop: false,
    mouseDrag: true,
    touch: true,
    controls: false,
    arrowKeys: true,
    prevButton: true,
    nextButton: true,
    // mode: 'gallery',
    // controlsPosition: 'bottom',
    // controlsText: ['<svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15 19l-7-7 7-7"/></svg>', '<svg class="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 5l7 7-7 7"/></svg>'],
  });
}

import { Application } from "@hotwired/stimulus"

const application = Application.start()


import intlTelInput from 'intl-tel-input'
application.register('intl-tel-input', intlTelInput)

// Import and register all TailwindCSS Components
import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

// Configure Stimulus development experience
application.debug = true
window.Stimulus   = application

document.addEventListener("slider-initialized", function() {
    console.log("Global listener caught slider-initialized");

     // Get all elements with the 'data-controller="session-switch"' attribute
    const sessionSwitchElements = document.querySelectorAll('[data-controller="session-switch"]');
    console.log("sessionSwitchElements:", sessionSwitchElements);


    // Loop through each element and call initializeButton on its controller
    sessionSwitchElements.forEach((element) => {
        const controller = application.getControllerForElementAndIdentifier(element, "session-switch");
        controller.initializeButton();
    });


});


export { application }



// const observer = lozad('.lozad', {
//     rootMargin: '50px 0px', // Apply a 50px margin to the viewport boundary to start loading images before they are visible
//     threshold: 0.1 // Load images when they are 10% visible in the viewport
//   });
  
//   observer.observe(); // Start observing the images with the lozad class
  