import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message"];
  static values = {
    timeout: Number
  };

  initialize() {
    if (this.hasTimeoutValue) {
      this.dismissAfterTimeout();
    }
  }

  dismissAfterTimeout() {
    setTimeout(() => {
      this.dismiss();
    }, this.timeoutValue);
  }

  dismiss() {
    this.element.remove();
  }

}