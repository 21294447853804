import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  toggle(event) {
    console.log("toggle");
    let parentDiv = event.currentTarget;
    let button = parentDiv.querySelector('.h-20');
  
    // Toggle the selection state of the button
    button.classList.toggle('selected');
  
    let day = parentDiv.getAttribute('data-availability-day-value');
    let time = parentDiv.getAttribute('data-availability-time-value');
  
    // If the button is selected, create a new hidden field
    if (button.classList.contains('selected')) {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", `availability[${day}][]`);
      input.setAttribute("value", time);
      this.formTarget.appendChild(input);
      
      // Remove the gray background and add blue
      button.classList.remove('bg-gray-200');
      button.classList.add('bg-blue-500');
    } else {
      // If the button is deselected, remove the hidden field
      // let input = this.formTarget.querySelector(`input[name="availability[${day}][]"][value="${time}"]`);
      // this.formTarget.removeChild(input);
      let input = this.formTarget.querySelector(`input[name="availability[${day}][]"][value="${time}"]`);
      if (input) {
        input.remove();
      }
      
      // Remove the blue background and add gray
      button.classList.remove('bg-blue-500');
      button.classList.add('bg-gray-200');
    }
  }
  

  }

