import { Controller } from "@hotwired/stimulus"

const NEUTRAL_BG_COLOR = "bg-gray-100";
const DECOR_BG_COLOR = "bg-amber-300";

export default class extends Controller {
  static values = {
    booked: Boolean,
    timeutc: String
  };

  toggleClass(element, removeClass, addClass) {
    element.classList.remove(removeClass);
    element.classList.add(addClass);
  }

  resetActiveButton() {
    const activeButton = document.querySelector(".active.time-button");
    if (activeButton) {
      this.toggleClass(activeButton, DECOR_BG_COLOR, NEUTRAL_BG_COLOR);
      activeButton.classList.remove("active");  // Remove the "active" class
    }
  }

  updateDisplayElements(startTime, endTime) {
    const choiceDisplay = document.getElementById("date-display");
    const timeDisplay = document.getElementById("time-display");
    const startDateStr = startTime.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
    const startTimeStr = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const endTimeStr = endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    choiceDisplay.innerHTML = startDateStr;
    timeDisplay.innerHTML = `${startTimeStr} - ${endTimeStr}`;
  }

  updateCheckoutButton() {
    const btnCheckout = document.getElementById("btn-checkout");
    this.toggleClass(btnCheckout, "cursor-not-allowed", "cursor-pointer");
    this.toggleClass(btnCheckout, "bg-btn-inactive", "bg-btn-active");
    this.toggleClass(btnCheckout, "bg-amber-200", "bg-amber-400");
    btnCheckout.disabled = false;
  }

  connect() {
    const textElements = this.element.querySelectorAll('p');
    if (this.bookedValue) {
      textElements.forEach(el => el.classList.add("text-gray-400"));  // Lighter grey text
    }
  }
  

  select() {
    if (this.bookedValue) {
      return;
    }
    this.resetActiveButton();

    this.toggleClass(this.element, NEUTRAL_BG_COLOR, DECOR_BG_COLOR);
    this.element.classList.add("active");

    const activeSessionTypeBtn = document.querySelector(".session-type-btn.active");
    const duration = activeSessionTypeBtn.dataset.duration;
    const startTime = new Date(this.timeutcValue);
    const endTime = new Date(startTime.getTime() + duration * 60000);

    this.updateDisplayElements(startTime, endTime);

    const timeutcFormField = document.getElementById("checkout-timeutc");
    timeutcFormField.value = this.timeutcValue;

    this.updateCheckoutButton();
  }
}
