import { Controller } from "@hotwired/stimulus"
// import intlTelInput from 'intl-tel-input';


import intlTelInput from 'intl-tel-input';

export default class extends Controller {
    static targets = [ "input" ]

    connect() {
        const input = this.inputTarget;
        intlTelInput(input, {
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
            hiddenInput: "phone",
            nationalMode: false,
            initialCountry: "us",
            preferredCountries: ["us", "ca", "mx"],
            separateDialCode: true,
            autoPlaceholder: "aggressive"
        });
    }
  
    disconnect() {
      // destroy the intlTelInput instance
      // this.inputTarget.intlTelInput("destroy");
    }
  
  }