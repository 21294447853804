import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.lastScrollTop = 0;
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    let scrollTop = window.scrollY;
    // console.log("scrollTop: ", scrollTop);
    if (scrollTop > 0) {
      // Scrolling down or up but not at the top
      this.element.classList.remove("bg-opacity-100");
      this.element.classList.add("bg-opacity-75");
    } else {
      // At the top of the page
      this.element.classList.remove("bg-opacity-75");
      this.element.classList.add("bg-opacity-100");
    }

    this.lastScrollTop = scrollTop;
  }
}
