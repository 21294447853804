import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  async submit(event) {
    // Prevent form submission
    event.preventDefault()

    // Disable the button and show the spinner
    const btn = this.element.querySelector('#btn-checkout')
    btn.disabled = true
    btn.querySelector('#spinner').style.display = 'inline-block'
    
    // Make an AJAX request to the URL specified in the form's action attribute
    const response = await fetch(this.element.action, {
      method: this.element.method,
      body: new FormData(this.element)
    })

    if (response.ok) {
      const responseData = await response.json()

      // Redirect to the received URL
      if (responseData.url) {
        window.location.href = responseData.url;
      } else {
        console.error('URL missing in server response', responseData);
      }

      
    } else {
      console.error('Something went wrong', response)
    }

    // Enable the button and hide the spinner
    btn.disabled = false
    btn.querySelector('#spinner').style.display = 'none'
  };

  connect() {
    
  };
  
}
