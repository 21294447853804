import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        'navigation'
    ];
    
  toggleMenu() {
    this.navigationTarget.classList.toggle('hidden');
  }
    
  connect() {
    console.log("mobile menu controller connected");
  };
};