import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  isSelecting = false;
  shouldActivate = false;

  connect() {
    this.element.addEventListener("touchstart", this.startSelecting.bind(this));
    this.element.addEventListener("touchmove", this.select.bind(this));
    this.element.addEventListener("touchend", this.stopSelecting.bind(this));
    this.element.addEventListener("mousedown", this.startSelecting.bind(this));
    this.element.addEventListener("mousemove", this.select.bind(this));
    this.element.addEventListener("mouseup", this.stopSelecting.bind(this));
  }

  startSelecting(event) {
    const element = event.target.closest("[data-availability-time-value]");
    if (element) {  // Only proceed if the event target is a time slot element
      event.preventDefault();
      this.isSelecting = true;
      this.shouldActivate = !element.querySelector('.h-20').classList.contains('selected');
      this.toggleSlot(element);
    }
  }
  

  select(event) {
    if (this.isSelecting) {
      const touch = event.touches ? event.touches[0] : event;
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      if (element && element.closest("[data-availability-time-value]")) {
        this.toggleSlot(element.closest("[data-availability-time-value]"));
      }
    }
  }

  stopSelecting() {
    this.isSelecting = false;
  }

  toggleSlot(element) {
    if (element) {
      const button = element.querySelector('.h-20');
      if (this.shouldActivate === button.classList.contains('selected')) return;
      const clickEvent = new Event("click", { bubbles: true, cancelable: true });
      element.dispatchEvent(clickEvent);
    }
  }
}