import { Controller } from "@hotwired/stimulus"

const NEUTRAL_BG_COLOR = "bg-slate-400";
const DECOR_BG_COLOR = "bg-amber-300";

export default class extends Controller {
  static values = {
    idx: String,
    price: String,
    info: String,
    sessiontype: String
  };

  switch() {
    this.resetActiveButtons();
    this.setActiveButton();
    this.updateOtherDisplays();
    this.updateExpectDisplay();
    this.resetTimeButtons();
  }

  resetActiveButtons() {
    const activeButtons = document.querySelectorAll(".active.session-type-btn");
    activeButtons.forEach(button => {
      button.classList.remove(DECOR_BG_COLOR, "active");
      button.classList.add(NEUTRAL_BG_COLOR);
    });
  }

  setActiveButton() {
    this.element.classList.remove(NEUTRAL_BG_COLOR);
    this.element.classList.add("active", DECOR_BG_COLOR);
  }

  updateOtherDisplays() {
    const priceDisplay = document.getElementById("price-display");
    const sessiontypeFormField = document.getElementById("checkout-sessiontype");
    const timeDisplay = document.getElementById("time-display");    
    const dateDisplay = document.getElementById("date-display");
    const btnCheckout = document.getElementById("btn-checkout");

    priceDisplay.innerHTML = this.priceValue;
    sessiontypeFormField.value = this.sessiontypeValue;
    timeDisplay.innerHTML = "";
    dateDisplay.innerHTML = "Pick one of the above time slots";

    btnCheckout.classList.replace("bg-btn-active", "bg-btn-inactive");
    btnCheckout.classList.replace("cursor-pointer", "cursor-not-allowed");
    btnCheckout.classList.replace("bg-amber-400", "bg-amber-200");
    btnCheckout.disabled = true;
  }

  updateExpectDisplay() {
    const whatToExpectDisplay = document.getElementById("what-to-expect-display");
    whatToExpectDisplay.innerHTML = `<h3 class=" font-bold">${this.sessiontypeValue}</h3><p>${this.infoValue}</p>`;
  }

  resetTimeButtons() {
    const timeButtons = document.querySelectorAll(".time-button");
    timeButtons.forEach(button => {
      button.classList.remove("bg-amber-300", "active");
      button.classList.add("bg-gray-100");
    });
  }

  connect() {
    if (this.idxValue === "0") {
      this.setActiveButton();
      this.updateOtherDisplays();
      this.updateExpectDisplay();
    }
  }
}
