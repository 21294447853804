import { Controller } from "@hotwired/stimulus"



// Connects to data-controller="phone-country-select"
export default class extends Controller {
  
  connect() {
    // we want only the country code to be displayed in the select
    // we need to restore the initial value after when the user klicks again on the select
    // And when the element is initialized, we want to display only the country code
    const selectElement = this.element;
    const selectedOptionText = selectElement.options[selectElement.selectedIndex].text;
    console.log(selectedOptionText);
    const countryCode = "+" + selectElement.value;
    console.log(countryCode);
    selectElement.options[selectElement.selectedIndex].text = countryCode;


  }

  
  displayCountryNumberOnly(event) {
    const selectElement = event.target;
    const selectedOptionText = selectElement.options[selectElement.selectedIndex].text;
    console.log(selectedOptionText);
    const countryCode = "+" + selectElement.value;
    console.log(countryCode); 
    selectElement.options[selectElement.selectedIndex].text = countryCode;

    // we need to restore the initial value after when the user klicks again on the select
    selectElement.addEventListener('click', function () {
      selectElement.options[selectElement.selectedIndex].text = selectedOptionText;
    }
    );
    
  
  }
}
